.modal-root {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  .modal-wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    max-height: max-content;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-box {
      margin: auto;
      .modal {
        background: #fff;
        min-height: 30px;
        min-width: 150px;
        border-radius: 4px;
        padding: 24px;
        box-shadow: 0px -5px 15px rgba(51, 51, 51, 0.12);
        .modal-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 24px;
          button {
            font-weight: 800;
            font-size: 20px;
            color: #7d7d7d;
            width: 20px;
            height: 20px;
            text-align: center;
            background-color: #ffffff;
            border: 0;
            cursor: pointer;
            &:hover {
              color: #006693;
            }
          }
        }
        .modal-body {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
