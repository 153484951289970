.button-group {
  display: flex;
  width: 100%;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
  &.alignment-left {
    justify-content: start;
  }
  &.alignment-center {
    justify-content: center;
  }
  &.alignment-right {
    justify-content: end;
  }
}
