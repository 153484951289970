button.mp-button {
  padding: 6px 10px;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1rem;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
  color: #ffffff;
  &[disabled] {
    opacity: 0.5;
  }
  &:not([disabled]) {
    cursor: pointer;
  }

  &.colored.normal {
    background-color: #e2e2e2;
    color: #333333;
    border: 1px solid #aaaaaa;
    &:hover:not([disabled]) {
      background-color: #999999;
      color: #ffffff;
    }
  }
  &.colored.primary,
  &.colored.green {
    background-color: #8ec549;
    border: 1px solid #75ac30;
    &:hover:not([disabled]) {
      background-color: #75ac30;
    }
  }
  &.colored.blue {
    background-color: #006693;
    border: 1px solid #0a2240;
    &:hover:not([disabled]) {
      background-color: #0a2240;
    }
  }
  &.colored.yellow {
    background-color: #ffae00;
    border: 1px solid #e69500;
    &:hover:not([disabled]) {
      background-color: #e69500;
    }
  }
  &.colored.orange {
    background-color: #ed513b;
    border: 1px solid #d43822;
    &:hover:not([disabled]) {
      background-color: #d43822;
    }
  }
  &.colored.red-preorder {
    background-color: #d02c50;
    border: 1px solid #b71337;
    &:hover:not([disabled]) {
      background-color: #b71337;
    }
  }
  &.colored.red {
    background-color: #ae4646;
    border: 1px solid #7b1313;
    &:hover:not([disabled]) {
      background-color: #7b1313;
    }
  }
  &.colored.brown {
    background-color: #ad8b35;
    border: 1px solid #7a5802;
    &:hover:not([disabled]) {
      background-color: #7a5802;
    }
  }
  &.hollow.normal {
    background-color: #fff;
    color: #aaaaaa;
    border: 1px solid #aaaaaa;
    &:hover:not([disabled]) {
      background-color: #fff;
      color: #aaaaaa;
    }
  }
  &.hollow.blue {
    background-color: #fff;
    color: #006693;
    border: 1px solid #006693;
    &:hover:not([disabled]) {
      background-color: #006693;
      color: #fff;
    }
  }
  &.hollow.primary,
  &.hollow.green {
    background-color: #fff;
    color: #8ec549;
    border: 1px solid #8ec549;
    &:hover:not([disabled]) {
      background-color: #8ec549;
      color: #fff;
    }
  }
  &.hollow.yellow {
    background-color: #fff;
    color: #ffae00;
    border: 1px solid #ffae00;
    &:hover:not([disabled]) {
      background-color: #ffae00;
      color: #fff;
    }
  }
  &.hollow.orange {
    background-color: #fff;
    color: #ff8000;
    border: 1px solid #ff8000;
    &:hover:not([disabled]) {
      background-color: #ff8000;
      color: #fff;
    }
  }
  &.hollow.red-preorder {
    background-color: #ffeaee;
    color: #d02c50;
    border: 1px solid #d02c50;
    &:hover:not([disabled]) {
      background-color: #d02c50;
      color: #fff;
    }
  }
  &.hollow.red {
    background-color: #ffeaea;
    color: #ae4646;
    border: 1px solid #ae4646;
    &:hover:not([disabled]) {
      background-color: #ae4646;
      color: #fff;
    }
  }
  &.hollow.brown {
    background-color: #fff;
    color: #ad8b35;
    border: 1px solid #ad8b35;
    &:hover:not([disabled]) {
      background-color: #ad8b35;
      color: #fff;
    }
  }
}
