%antiSiteCSS {
  font-family: "proxima-nova", "Segoe UI", "Open Sans";
  color: #333;
  line-height: 1.2rem;
  font-size: 0.8rem;
  [class*=" icon-"] {
    margin-right: auto;
  }
  input {
    font-size: 1.2rem;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: revert;
    font-weight: revert;
    margin: revert;
    padding: revert;
    line-height: normal;
  }
  a {
    color: #006693;
    text-decoration: none;
    cursor: pointer;
  }
}

.react-page {
  @extend %antiSiteCSS;
}

.modal-root {
  z-index: 300000;
  & * {
    @extend %antiSiteCSS;
  }
}
